<template lang="pug">
div
  template(v-if="type === 'pending'")
    .next-billing-coupon
      .next-billing-coupon-icon-bg
        inline-svg(
          :src="require(`@/assets/admin/svg/icons/promote-offer.svg`)"
          :width="29"
          :height="20"
        )
      .next-billing-coupon-details
        div {{ $t('plan.notifications.nextBillingCoupon.message', { billingCycle: period }) }}
        div {{ $t('plan.notifications.nextBillingCoupon.pending.message') }}
      .next-billing-coupon-right
        a.cursor-pointer(@click="$emit('action')")
          span {{ $t('plan.notifications.nextBillingCoupon.pending.button') }}
          span.right-angle
            uil-angle-right-b

  template(v-if="type === 'next-billing' && couponDetails")
    .next-billing-coupon
      .next-billing-coupon-icon-bg
        inline-svg(
          :src="require(`@/assets/admin/svg/icons/promote-offer.svg`)"
          :width="29"
          :height="20"
        )
      .next-billing-coupon-details
        div {{ $t('plan.notifications.nextBillingCoupon.message', { billingCycle: period }) }}
        div
          span(
            v-html="$t('plan.notifications.nextBillingCoupon.approved.message', { couponName: couponDetails.couponDescription })"
          )
          span.pl-1(
            v-if="couponDetails.couponValidityPeriod"
            v-html="$t('plan.notifications.nextBillingCoupon.approved.period', { couponStart: couponDetails.couponValidityPeriod.start, couponEnd: couponDetails.couponValidityPeriod.end })"
          )
</template>

<script>
  import InlineSvg from 'vue-inline-svg';
  import { createNamespacedHelpers } from 'vuex';
  import { UilAngleRightB } from '@iconscout/vue-unicons';

  const { mapGetters: paymentGetters } = createNamespacedHelpers('payment');

  export default {
    components: {
      UilAngleRightB,
      InlineSvg,
    },
    props: {
      type: { type: String, required: true },
      couponDetails: { type: Object, default: null },
    },
    computed: {
      ...paymentGetters(['overrunMaximumTotal', 'getCurrentPeriodLanguageKey']),
      period() {
        return this.$t(`billingCycle.${this.getCurrentPeriodLanguageKey}`);
      },
    },
  };
</script>

<style lang="sass" scoped>
  @import '../../sass/variables/_colors'

  .next-billing-coupon
    display: flex
    background-color: $om-alert-green-500-20
    border-radius: 4px
    padding: 10px 16px
    color: $om-alert-green-700

    display: flex
    flex-direction: row
    align-items: center

    &-right
      flex: 0 0 200px
      display: flex
      justify-content: flex-end
      align-items: center
    a
      font-weight: 500
    .right-angle
      padding-left: 5px

    &-icon-bg
      flex: 0 0 56px
      display: flex
      width: 56px
      height: 56px
      background: $om-alert-green-600-10
      border-radius: 100px
      justify-content: center
      align-items: center

    &-details
      padding-left: 10px
      width: 100%
</style>
